import React, { useEffect, useState } from "react";
import "../assets/style/globalStyle.scss";
import YouTube from "react-youtube";

import NavBar from "../components/navBarSite";
import EventCard from "../components/eventCard";

export function useMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

const EventPage = () => {
  const isPhone = useMedia("(max-width: 767px)");
  const isTablet = useMedia("(min-width: 768px) and (max-width: 1224px)");
  const isLaptop = useMedia("(min-width: 1225px) and (max-width: 1500px)");

  const opts = {
    height: isPhone ? "209" : "500",
    width: isPhone ? "100%" : "750",
    marginRight: 20,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const opts2 = {
    height: "400",
    width: "450",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const styles = {
    sectionTitles: {
      fontWeight: 600,
      fontSize: "18px",
      color: "#F56D44",
    },
    sectionSubTitle: {
      fontWeight: 800,
      fontSize: "48px",
      color: "black",
    },
    ytbBouton: {
      fontSize: "14px",
      fontWeight: 700,
      color: "white",
      backgroundImage: `linear-gradient(90deg, #D85E4B, #A15477)`,
      borderRadius: 25,
      alignItems: "center",
      textAlign: "center",
      paddingTop: 14,
      paddingBottom: 14,
      marginTop: 30,
      marginBottom: isPhone ? 37 : 150,
      width: isPhone ? "100%" : 279,
      alignSelf: "center",
    },
  };

  return (
    <div style={{ margin: 0, padding: 0, paddingTop: 120 }}>
      <NavBar></NavBar>
      <div>
        {/* SECTION EVENEMENTS */}
        {isTablet ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingLeft: 18,
              paddingRight: 18,
            }}
          >
            <p style={styles.sectionTitles}>Nos évènements</p>
            <p style={styles.sectionSubTitle}>Calendrier</p>
            <p style={{ width: 800, marginTop: 35, textAlign: "center" }}>
              Ball'N Connect organise également de nombreux tournois tels que
              les Boss du Playground ou même le Last Round. Retrouvez ici le
              calendrier de nos prochains événements ainsi qu'une vidéo de
              présentation d'un de nos fameux évènements le Ball'N Street.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: 52,
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <YouTube videoId="wtyrlFVfpC8" opts={isLaptop ? opts2 : opts} />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: 800,
                      color: "black",
                      marginBottom: 30,
                    }}
                  >
                    À venir
                  </p>
                  <EventCard
                    link={"https://linktr.ee/weareballnconnect"}
                    day={"27"}
                    month={"JUIL."}
                    name={"Boss du playground"}
                    city={"Lille"}
                    adress={"Place François Mitterrand"}
                    hour={"11h00 - 19h00"}
                  ></EventCard>
                  {/* <EventCard
                    link={"https://linktr.ee/weareballnconnect"}
                    day={"26"}
                    month={"JUIL."}
                    name={"Boss du playground 'Remember The Why'"}
                    city={"Paris"}
                    adress={"Bir Hakeim"}
                    hour={"10h - 18h00"}
                  ></EventCard>
                  <EventCard
                    day={"28"}
                    month={"JUIL."}
                    name={"Boss du playground"}
                    city={"La Rochelle"}
                    hour={"10h00 - 18h00"}
                    link={"https://linktr.ee/weareballnconnect"}
                  ></EventCard> */}
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 66,
                    marginLeft: 25,
                  }}
                >
                  <EventCard
                    adress={"Charles L'hermite"}
                    day={"26"}
                    month={"AOUT"}
                    name={"Boss du playground"}
                    city={"Paris"}
                    hour={"10h00 - 18h00"}
                    link={"https://linktr.ee/weareballnconnect"}
                  ></EventCard>
                  <EventCard
                    day={"26"}
                    month={"AOUT"}
                    name={"Boss du playground"}
                    city={"Toulouse"}
                    adress={"La Toulouserie"}
                    hour={"10h00 - 18h00"}
                    link={"https://linktr.ee/weareballnconnect"}
                  ></EventCard>
                  <EventCard
                    day={"26"}
                    month={"AOUT"}
                    name={"Boss du playground"}
                    city={"Nice"}
                    adress={"Stade Vauban"}
                    hour={"10h00 - 18h00"}
                    link={"https://linktr.ee/weareballnconnect"}
                  ></EventCard>
                </div> */}
              </div>
            </div>
            <a
              href="https://www.youtube.com/channel/UC3rjCpvj99gSxsksJEIqgUg"
              style={styles.ytbBouton}
            >
              NOS ÉVÈNEMENTS EN VIDÉO
            </a>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: isPhone ? "flex-start" : "center",
              paddingLeft: isPhone ? 18 : 0,
              paddingRight: isPhone ? 18 : 0,
            }}
          >
            <p style={styles.sectionTitles}>Nos évènements</p>
            <p style={styles.sectionSubTitle}>Calendrier</p>
            <p
              style={{
                width: isPhone ? "" : 800,
                marginTop: 35,
                textAlign: isPhone ? "start" : "center",
              }}
            >
              Ball'N Connect organise également de nombreux tournois tels que
              les Boss du Playground ou même le Last Round. Retrouvez ici le
              calendrier de nos prochains événements ainsi qu'une vidéo de
              présentation d'un de nos fameux évènements le Ball'N Street.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: 52,
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <YouTube videoId="wtyrlFVfpC8" opts={isLaptop ? opts2 : opts} />
              {isPhone && (
                <a
                  href="https://www.youtube.com/channel/UC3rjCpvj99gSxsksJEIqgUg"
                  style={styles.ytbBouton}
                >
                  NOS ÉVÈNEMENTS EN VIDÉO
                </a>
              )}
              {!isPhone ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        fontSize: "24px",
                        fontWeight: 800,
                        color: "black",
                        marginBottom: 30,
                      }}
                    >
                      À venir
                    </p>
                    <EventCard
                      link={"https://linktr.ee/weareballnconnect"}
                      day={"27"}
                      month={"JUIL."}
                      name={"Boss du playground"}
                      city={"Lille"}
                      adress={"Place François Mitterrand"}
                      hour={"11h00 - 19h00"}
                    ></EventCard>
                    {/* <EventCard
                      link={"https://linktr.ee/weareballnconnect"}
                      day={"26"}
                      month={"JUIL."}
                      name={"Boss du playground 'Remember The Why'"}
                      city={"Paris"}
                      adress={"Bir Hakeim"}
                      hour={"10h - 18h00"}
                    ></EventCard>
                    <EventCard
                      day={"28"}
                      month={"JUIL."}
                      name={"Boss du playground"}
                      city={"La Rochelle"}
                      hour={"10h00 - 18h00"}
                      link={"https://linktr.ee/weareballnconnect"}
                    ></EventCard> */}
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 66,
                      marginLeft: 25,
                    }}
                  >
                    <EventCard
                      adress={"Charles L'hermite"}
                      day={"26"}
                      month={"AOUT"}
                      name={"Boss du playground"}
                      city={"Paris"}
                      hour={"10h00 - 18h00"}
                      link={"https://linktr.ee/weareballnconnect"}
                    ></EventCard>
                    <EventCard
                      day={"26"}
                      month={"AOUT"}
                      name={"Boss du playground"}
                      city={"Toulouse"}
                      adress={"La Toulouserie"}
                      hour={"10h00 - 18h00"}
                      link={"https://linktr.ee/weareballnconnect"}
                    ></EventCard>
                    <EventCard
                      day={"26"}
                      month={"AOUT"}
                      name={"Boss du playground"}
                      city={"Nice"}
                      adress={"Stade Vauban"}
                      hour={"10h00 - 18h00"}
                      link={"https://linktr.ee/weareballnconnect"}
                    ></EventCard>
                  </div> */}
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: 800,
                      color: "black",
                      marginBottom: 30,
                    }}
                  >
                    À venir
                  </p>
                  <EventCard
                    link={"https://linktr.ee/weareballnconnect"}
                    day={"27"}
                    month={"JUIL."}
                    name={"Boss du playground"}
                    city={"Lille"}
                    adress={"Place François Mitterrand"}
                    hour={"11h00 - 19h00"}
                  ></EventCard>
                  {/* <EventCard
                    link={"https://linktr.ee/weareballnconnect"}
                    day={"26"}
                    month={"JUIL."}
                    name={"Boss du playground 'Remember The Why'"}
                    city={"Paris"}
                    adress={"Bir Hakeim"}
                    hour={"10h - 18h00"}
                  ></EventCard>
                  <EventCard
                    day={"28"}
                    month={"JUIL."}
                    name={"Boss du playground"}
                    city={"La Rochelle"}
                    hour={"10h00 - 18h00"}
                    link={"https://linktr.ee/weareballnconnect"}
                  ></EventCard>
                  <EventCard
                    adress={"Charles L'hermite"}
                    day={"26"}
                    month={"AOUT"}
                    name={"Boss du playground"}
                    city={"Paris"}
                    hour={"10h00 - 18h00"}
                    link={"https://linktr.ee/weareballnconnect"}
                  ></EventCard>
                  <EventCard
                    day={"26"}
                    month={"AOUT"}
                    name={"Boss du playground"}
                    city={"Toulouse"}
                    adress={"La Toulouserie"}
                    hour={"10h00 - 18h00"}
                    link={"https://linktr.ee/weareballnconnect"}
                  ></EventCard>
                  <EventCard
                    day={"26"}
                    month={"AOUT"}
                    name={"Boss du playground"}
                    city={"Nice"}
                    adress={"Stade Vauban"}
                    hour={"10h00 - 18h00"}
                    link={"https://linktr.ee/weareballnconnect"}
                  ></EventCard> */}
                </div>
              )}
            </div>
            {!isPhone && (
              <a
                href="https://www.youtube.com/channel/UC3rjCpvj99gSxsksJEIqgUg"
                style={styles.ytbBouton}
              >
                NOS ÉVÈNEMENTS EN VIDÉO
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventPage;
